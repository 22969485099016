<template>
    <table class="ml-1 mt-4" style="width:100%" ref="supplierTable">
        <div class="relative">
            <tr v-if="customError.length > 0">
                <td colspan="2">
                    <span v-for="(item, key) in customError" :key="key">
                        <el-tag size="medium" type="danger" class="mb-1 mr-1"><i class="fas fa-info-circle mr-1" />{{ item.errorMessage }}</el-tag>
                    </span>
                </td>
            </tr>
            <tr v-if="supplierInvoice.uploadedByClient">
                <td>Uppladdad av klienten</td>
                <td><el-input type="text" size="medium" v-model="supplierInvoice.uploadedByClient" disabled /></td>
            </tr>
            <tr>
                <td>Klient</td>
                <td>
                    <ClientsSearch @on-selected="getClientSelectedValue" :selected="clientSearchOption.selected" style="width: 500px" size="medium" drop-down-width="500px" />
                </td>
                <span class="absolute" v-if="mlResults">
                    <td v-if="azureMlResult">
                        <MLConfidenceTag width="250px" :content="azureMlResult.client" />
                    </td>
                    <td v-if="tyMlResult">
                        <MLConfidenceTag width="250px" :content="tyMlResult.client" />
                    </td>
                    <td v-if="rossumMlResult">
                        <MLConfidenceTag width="250px" :content="rossumMlResult.client" />
                    </td>
                </span>
            </tr>
            <tr>
                <td>Leverantör från Rossum</td>
                <td><el-input type="text" size="medium" v-model="rossumFoundSupplierName" disabled /></td>
                <span class="absolute" v-if="mlResults">
                    <td v-if="azureMlResult">
                        <MLConfidenceTag width="250px" :content="azureMlResult.supplier" />
                    </td>
                    <td v-if="tyMlResult">
                        <MLConfidenceTag width="250px" :content="tyMlResult.supplier" />
                    </td>
                    <td v-if="rossumMlResult">
                        <MLConfidenceTag width="250px" :content="rossumMlResult.supplier" />
                    </td>
                </span>
            </tr>
            <tr>
                <td>Leverantör</td>
                <td>
                    <SupplierSearch style="width: 500px" @on-selected="getSupplierSelectedValue" :selected-name="selectedSupplierName" @iconClick="updateSupplier = !updateSupplier" required="true" />
                </td>
                <td>
                    <span class="cursor-pointer" @click="createSupplier = !createSupplier" style="color: rgba(65, 87, 112)">
                        <i class="fa-solid fa-circle-plus fa-xl" style="margin-top:18px" title="Registera Leverantör" />
                    </span>
                </td>
            </tr>
            <tr v-if="updateSupplier">
                <td colspan="2">
                    <UpdateSupplier :pre-selected-supplier="supplierSearchOption.selected" @success="refreshSupplierList" @close="updateSupplier = false" />
                </td>
            </tr>
            <tr v-if="createSupplier">
                <td colspan="2">
                    <CreateSupplier @success="refreshSupplierList" @close="createSupplier = false" />
                </td>
            </tr>
        </div>

        <div class="w-full">
            <tr class="text-gray-400 font-sans text-xs font-semibold">
                <td colspan="2" />
                <td v-if="azureMlResult"><p class="text-center">Azure</p></td>
                <td v-if="tyMlResult"><p class="text-center">Tuoyuan</p></td>
                <td v-if="rossumMlResult"><p class="text-center">Rossum</p></td>
            </tr>
            <tr>
                <td>Kontonummer</td>
                <td>
                    <el-input
                        placeholder="BG/PG/Kontonummer/IBAN"
                        type="text"
                        size="medium"
                        v-model="tempSupplierInvoice.accountNumber"
                        v-validate="'required'"
                        data-vv-delay="1000"
                        name="BG/PG/Kontonummer/IBAN"
                        :error="errors.first('BG/PG/Kontonummer/IBAN')"
                    />
                </td>
                <td v-if="azureMlResult"><MLConfidenceTag :content="azureMlResult.accountNumber" /></td>
                <td v-if="tyMlResult"><MLConfidenceTag :content="tyMlResult.accountNumber" /></td>
                <td v-if="rossumMlResult"><MLConfidenceTag :content="rossumMlResult.accountNumber" /></td>
            </tr>
            <tr>
                <td>Betalnings typ</td>
                <td>
                    <el-select class="w-full" size="medium" v-model="tempSupplierInvoice.tempPaymentType" placeholder="Välj valuta">
                        <el-option v-for="item in paymentType" :key="item.value" :label="item.name" :value="item.value > 0 ? item.value : ''" />
                    </el-select>
                </td>
            </tr>
            <tr v-if="!isSekInvoice">
                <td>BIC</td>
                <td><el-input size="medium" placeholder="BIC" type="text" v-model="tempSupplierInvoice.bic" /></td>
                <td v-if="azureMlResult"><MLConfidenceTag :content="azureMlResult.bic" /></td>
                <td v-if="tyMlResult"><MLConfidenceTag :content="tyMlResult.bic" /></td>
                <td v-if="rossumMlResult"><MLConfidenceTag :content="rossumMlResult.bic" /></td>
            </tr>
            <tr v-if="!isSekInvoice">
                <td>Skatterättslig hemvist</td>
                <td><el-input size="medium" type="text" v-model="residenceForTax" /></td>
            </tr>
            <tr v-if="!isSekInvoice">
                <td>Address</td>
                <td><el-input type="textarea" rows="2" v-model="tempSupplierInvoice.supplierAddress" /></td>
                <td v-if="azureMlResult"><MLConfidenceTag :content="azureMlResult.supplierAddress" /></td>
                <td v-if="tyMlResult"><MLConfidenceTag :content="tyMlResult.supplierAddress" /></td>
                <td v-if="rossumMlResult"><MLConfidenceTag :content="rossumMlResult.supplierAddress" /></td>
            </tr>
            <tr>
                <td>Faturadatum</td>
                <td>
                    <DateInputField placeholder="Faturadatum" size="medium" :pre-selected-value="tempSupplierInvoice.invoiceDate" @change="inputInvoiceDate($event)" />
                    <el-tag size="medium" type="warning" class="mb-1 mt-1 w-60 flex" v-if="invoiceDateWarning">
                        <i class="fas fa-info-circle mr-1 self-center" />
                        <p class="truncate ...">{{ invoiceDateWarning }}</p>
                    </el-tag>
                </td>
                <td v-if="azureMlResult"><MLConfidenceTag :content="azureMlResult.invoiceDate" /></td>
                <td v-if="tyMlResult"><MLConfidenceTag :content="tyMlResult.invoiceDate" /></td>
                <td v-if="rossumMlResult"><MLConfidenceTag :content="rossumMlResult.invoiceDate" /></td>
            </tr>
            <tr>
                <td>Förfallodatum</td>
                <td>
                    <DateInputField placeholder="Förfallodatum" size="medium" :pre-selected-value="tempSupplierInvoice.dueDate" @change="inputDueDate($event)" />
                    <el-tag size="medium" type="warning" class="mb-1 mt-1 w-60 flex" v-if="dueDateWarning">
                        <i class="fas fa-info-circle mr-1 self-center" />
                        <p class="truncate ...">{{ dueDateWarning }}</p>
                    </el-tag>
                </td>
                <td v-if="azureMlResult"><MLConfidenceTag :content="azureMlResult.dueDate" /></td>
                <td v-if="tyMlResult"><MLConfidenceTag :content="tyMlResult.dueDate" /></td>
                <td v-if="rossumMlResult"><MLConfidenceTag :content="rossumMlResult.dueDate" /></td>
            </tr>
            <tr>
                <td>Ocr/Meddelande</td>
                <td><el-input size="medium" placeholder="Ocr/Meddelande" type="text" v-model="tempSupplierInvoice.ocrNumber" /></td>
                <td v-if="azureMlResult"><MLConfidenceTag :content="azureMlResult.ocrNumber" /></td>
                <td v-if="tyMlResult"><MLConfidenceTag :content="tyMlResult.ocrNumber" /></td>
                <td v-if="rossumMlResult"><MLConfidenceTag :content="rossumMlResult.ocrNumber" /></td>
            </tr>
            <tr>
                <td>Faturanummer</td>
                <td>
                    <el-input
                        placeholder="Faturanummer"
                        type="text"
                        size="medium"
                        v-model="tempSupplierInvoice.invoiceNumber"
                        v-validate="'required'"
                        data-vv-delay="1000"
                        name="Faturanummer"
                        :error="errors.first('Faturanummer')"
                    />
                </td>
                <td v-if="azureMlResult"><MLConfidenceTag :content="azureMlResult.invoiceNumber" /></td>
                <td v-if="tyMlResult"><MLConfidenceTag :content="tyMlResult.invoiceNumber" /></td>
                <td v-if="rossumMlResult"><MLConfidenceTag :content="rossumMlResult.invoiceNumber" /></td>
            </tr>
            <tr>
                <td>Belopp</td>
                <td>
                    <div class="field">
                        <input-calc
                            placeholder="Belopp"
                            v-model="tempSupplierInvoice.totalGrossAmount"
                            right-text="SEK"
                            v-validate="'required'"
                            data-vv-delay="1000"
                            name="Belopp"
                            :error="errors.first('Belopp')"
                            @input="updateVoucherGrossAmount"
                        />
                    </div>
                </td>
                <td v-if="azureMlResult">
                    <MLConfidenceTag v-if="azureMlResult.grossAmount" :content="azureMlResult.grossAmount">{{ azureMlResult.grossAmount.value | swedishNumberFormat }}</MLConfidenceTag>
                </td>
                <td v-if="tyMlResult">
                    <MLConfidenceTag v-if="tyMlResult.grossAmount" :content="tyMlResult.grossAmount">{{ tyMlResult.grossAmount.value | swedishNumberFormat }}</MLConfidenceTag>
                </td>
                <td v-if="rossumMlResult">
                    <MLConfidenceTag v-if="rossumMlResult.grossAmount" :content="rossumMlResult.grossAmount">{{ rossumMlResult.grossAmount.value | swedishNumberFormat }}</MLConfidenceTag>
                </td>
            </tr>
            <tr>
                <td>Ex Moms</td>
                <td>
                    <input-calc
                        placeholder="Ex moms"
                        v-model="tempSupplierInvoice.totalNetAmount"
                        right-text="SEK"
                        v-validate="'required'"
                        data-vv-delay="1000"
                        name="Ex moms"
                        :error="errors.first('Ex moms')"
                        @input="updateVoucherNetAmount"
                    />
                </td>
                <td v-if="azureMlResult">
                    <MLConfidenceTag :content="azureMlResult.netAmount">{{ azureMlResult.netAmount.value | swedishNumberFormat }}</MLConfidenceTag>
                </td>
                <td v-if="tyMlResult">
                    <MLConfidenceTag :content="tyMlResult.netAmount">{{ tyMlResult.netAmount.value | swedishNumberFormat }}</MLConfidenceTag>
                </td>
                <td v-if="rossumMlResult">
                    <MLConfidenceTag :content="rossumMlResult.netAmount">{{ rossumMlResult.netAmount.value | swedishNumberFormat }}</MLConfidenceTag>
                </td>
            </tr>
            <tr v-if="!isReported">
                <td>Moms</td>
                <td>
                    <input-calc placeholder="Moms" v-model="tempSupplierInvoice.totalVat" right-text="SEK" @input="updateVoucherVatAmount" />
                </td>
                <td v-if="azureMlResult">
                    <MLConfidenceTag :content="azureMlResult.totalTax">{{ azureMlResult.totalTax.value | swedishNumberFormat }}</MLConfidenceTag>
                </td>
                <td v-if="tyMlResult">
                    <MLConfidenceTag :content="tyMlResult.totalTax">{{ tyMlResult.totalTax.value | swedishNumberFormat }}</MLConfidenceTag>
                </td>
                <td v-if="rossumMlResult">
                    <MLConfidenceTag :content="rossumMlResult.totalTax">{{ rossumMlResult.totalTax.value | swedishNumberFormat }}</MLConfidenceTag>
                </td>
            </tr>
            <tr>
                <td />
                <td class="align-middle">
                    <check-box v-model="tempSupplierInvoice.carVat" @change="applyBilmoms()" label="Bilmoms" />
                </td>
            </tr>
            <tr>
                <td>Avrundning</td>
                <td>
                    <input-calc placeholder="Avrundning" v-model="tempSupplierInvoice.rounding" right-text="SEK" :allow-zero-start="true" @input="updateVoucherRounding" />
                </td>
                <td v-if="azureMlResult">
                    <MLConfidenceTag :content="azureMlResult.rounding">{{ azureMlResult.rounding.value | swedishNumberFormat }}</MLConfidenceTag>
                </td>
                <td v-if="tyMlResult">
                    <MLConfidenceTag :content="tyMlResult.rounding">{{ tyMlResult.rounding.value | swedishNumberFormat }}</MLConfidenceTag>
                </td>
                <td v-if="rossumMlResult">
                    <MLConfidenceTag :content="rossumMlResult.rounding">{{ rossumMlResult.rounding.value | swedishNumberFormat }}</MLConfidenceTag>
                </td>
            </tr>
            <tr>
                <td>Påminnelseavgift</td>
                <td>
                    <input-calc placeholder="Påminnelseavgift" v-model="tempSupplierInvoice.reminderFee" right-text="SEK" :allow-zero-start="true" @input="updateVoucherReminderFee" />
                </td>
            </tr>
            <tr v-if="isReported">
                <td>Omvänd moms</td>
                <td>
                    <input-calc placeholder="Omvänd moms" v-model="reverseVat" right-text="SEK" />
                </td>
            </tr>
            <tr>
                <td>Valuta</td>
                <td v-if="currenciesList">
                    <el-select class="w-full" size="medium" v-model="selectedCurrency" placeholder="Välj valuta" @change="convertValues()">
                        <el-option v-for="item in currenciesList" :key="item.value" :label="item.name" :value="item" />
                    </el-select>
                </td>
                <td v-if="azureMlResult"><MLConfidenceTag :content="azureMlResult.currency" /></td>
                <td v-if="tyMlResult"><MLConfidenceTag :content="tyMlResult.currency" /></td>
                <td v-if="rossumMlResult"><MLConfidenceTag :content="rossumMlResult.currency" /></td>
            </tr>
            <tr v-if="!isSekInvoice">
                <td>Utländskt Belopp</td>
                <td>
                    <base-input-numeric
                        placeholder="Utländskt Belopp"
                        v-model="tempSupplierInvoice.internationalGrossAmount"
                        v-validate="internationalRequiredRule"
                        data-vv-delay="1000"
                        name="utländskt Belopp"
                        :error="errors.first('utländskt Belopp')"
                    />
                </td>
                <td v-if="azureMlResult">
                    <MLConfidenceTag v-if="azureMlResult.grossAmount" :content="azureMlResult.grossAmount">{{ azureMlResult.grossAmount.value | swedishNumberFormat }}</MLConfidenceTag>
                </td>
                <td v-if="tyMlResult">
                    <MLConfidenceTag v-if="tyMlResult.grossAmount" :content="tyMlResult.grossAmount">{{ tyMlResult.grossAmount.value | swedishNumberFormat }}</MLConfidenceTag>
                </td>
                <td v-if="rossumMlResult">
                    <MLConfidenceTag v-if="rossumMlResult.grossAmount" :content="rossumMlResult.grossAmount">{{ rossumMlResult.grossAmount.value | swedishNumberFormat }}</MLConfidenceTag>
                </td>
            </tr>
            <supplier-radio
                :vat-registration-no="tempSupplierInvoice.supplierVatNo"
                :is-in-e-u="supplierInvoice.isVatNumInEurope"
                :moms="tempSupplierInvoice.totalVat"
                @selectedValues="getRadioValues"
                :selected="supplierInvoice.supplierRadioValues"
            />
            <tr v-if="$flags.Accruals">
                <td>Periodisera</td>
                <td><check-box v-model="supplierInvoice.isAccrual" @change="updateAccrualDates()" /></td>
            </tr>
            <tr v-if="supplierInvoice.isAccrual">
                <td>Startdatum</td>
                <td>
                    <DateInputField
                        size="medium"
                        placeholder="Startdatum"
                        :pre-selected-value="formatDate(tempSupplierInvoice.startDate)"
                        @change="(tempSupplierInvoice.startDate = $event), checkifPeriodize()"
                    />
                    <el-tag size="medium" type="danger" class="mb-1 mt-1" v-if="startDateError != null"> <i class="fas fa-info-circle" /> {{ startDateError.errorMessage }} </el-tag>
                </td>
            </tr>
            <tr v-if="supplierInvoice.isAccrual">
                <td>Slutdatum</td>
                <td>
                    <DateInputField
                        size="medium"
                        placeholder="Slutdatum"
                        :pre-selected-value="formatDate(tempSupplierInvoice.endDate)"
                        @change="(tempSupplierInvoice.endDate = $event), checkifPeriodize()"
                    />
                    <el-tag size="medium" type="danger" class="mb-1 mt-1" v-if="endDateError != null"> <i class="fas fa-info-circle" /> {{ endDateError.errorMessage }} </el-tag>
                </td>
            </tr>
        </div>

        <div>
            <tr>
                <td />
                <td class="pt-3">
                    <VouchersTable
                        :voucher-rows="voucherRows"
                        :account-plan-items="accountPlanItems"
                        :write-comments="false"
                        :periodize="supplierInvoice.isAccrual"
                        @updateValues="updateVoucherRow"
                        @newItemCreation="createNewItemComponent"
                        @removeVoucherRow="removeVoucherRow"
                        @showAccruals="showAccruals"
                    />
                    <el-button v-if="voucherError != null" :plain="true" type="danger" class="mb-1 mt-1"> <i class="fas fa-info-circle" /> {{ voucherError.errorMessage }} </el-button>
                </td>
            </tr>
            <tr v-if="supplierSearchOption.selected">
                <td />
                <td>
                    <a class="text-xs" @click="updateAccount = !updateAccount">Ändra standardkonto för leverantören</a>
                    <UpdateDefaultAccount
                        v-if="updateAccount"
                        :account-plan-items="accountPlanItems"
                        :pre-selected-ltd-account="isParsed(supplierSearchOption.selected.limitedDefaultAccount)"
                        :pre-selected-private-account="isParsed(supplierSearchOption.selected.individualDefaultAccount)"
                        :supplier-id="parseInt(supplierSearchOption.selected.value)"
                        @success="updateDefaultAccounts"
                        @close="updateAccount = false"
                    />
                </td>
            </tr>
            <tr v-if="supplierInvoice.amlApprovedBy != null">
                <td class="pt-3">Godkännare</td>
                <td class="pt-3"><el-input disabled v-model="supplierInvoice.amlApprovedBy" /></td>
            </tr>
            <tr v-if="supplierInvoice.amlApprovedBy != null">
                <td class="pt-3">Godkänt datum</td>
                <td class="pt-3"><el-input disabled v-model="supplierInvoice.amlApprovedDate" /></td>
            </tr>
            <tr v-if="supplierInvoice.amlApprovedBy != null">
                <td class="pt-3">Godkännarekommentar</td>
                <td class="pt-3"><el-input disabled type="textarea" v-model="supplierInvoice.amlApproverComment" /></td>
            </tr>
            <tr>
                <td class="pt-3">Credit note</td>
                <td class="pt-3"><check-box v-model="supplierInvoice.isCreditNote" /></td>
            </tr>
            <tr>
                <td>Kommentar</td>
                <td><el-input type="textarea" placeholder="Kommentar" v-model="supplierInvoice.comments" /></td>
            </tr>
            <tr>
                <td style="padding-top: 15px" colspan="2">
                    <supplier-buttons
                        ref="supplierButtons"
                        :disable-booking-button="supplierInvoice.isCreditNote"
                        :supplier-invoice="supplierInvoice"
                        @saveItems="saveInvoiceData()"
                        @createReceipt="createReceipt()"
                        @deny="denyInvoice()"
                        @deleteInvoice="deleteInvoice()"
                        @bookInvoice="bookInvoice()"
                    />
                </td>
            </tr>
        </div>

        <AccrualModal :visible="accrualModal" :data="accrualModalData" @close="accrualModal = false" :accruals-for-data="selectAccruals" />
    </table>
</template>
<script>
import "moment/locale/se";
import { mapState } from "vuex";
import moment from "moment";
import Api from "../../supplierInvoice.api";
import Enum from "../../supplierInvoice.enum";

export default {
    props: ["supplierInvoice"],

    data() {
        return {
            mlResults: [],
            isNewSupplier: false,
            selectAccruals: {},
            accrualModal: false,
            accrualModalData: [],
            updateAccount: false,
            createSupplier: false,
            updateSupplier: false,
            paymentType: [
                { value: 1, name: "BANKGIRO" },
                { value: 2, name: "PLUSGIRO" },
                { value: 3, name: "BANKKONTO" },
                { value: 4, name: "IBAN" },
            ],
            invoiceDateWarning: undefined,
            dueDateWarning: undefined,
            customError: [],
            radioValues: undefined,
            isInvoiceSek: true,
            isReported: false,
            reverseVat: undefined,
            defaultAccount: undefined,
            supplierAccountValues: [],
            clientSearchOption: [],
            supplierSearchOption: [],
            selectedCompanyType: 0,
            currenciesList: undefined,
            selectedCurrency: { amount: 1, value: "SEK", name: "SEK (Svenska kronor)" },
            supplierRadioValues: {
                supplierCountry: undefined,
                reverseConstruction: undefined,
                momsNumber: undefined,
                vatRate: undefined,
            },
            rossumFoundSupplierName: undefined,
            voucherRows: [{ id: 1, account: 2440, debit: 0, credit: 0, text: "", readOnly: true, startDate: null, endDate: null }],
        };
    },

    components: {
        ClientsSearch: () => import("@/components/clientsSearch.vue"),
        "supplier-radio": () => import(/* webpackChunkName: "supplierInvoiceRadioBtn" */ "./supplierInvoiceRadioBtn"),
        "supplier-buttons": () => import(/* webpackChunkName: "SupplierButtons" */ "./supplierInvoiceButtons"),
        "base-input-numeric": () => import(/* webpackChunkName: "BaseInputNumeric" */ "@/components/baseInputNumeric.vue"),
        "check-box": () => import(/* webpackChunkName: "CheckBox" */ "@/components/content/CheckBox"),
        "input-calc": () => import(/* webpackChunkName: "BaseInputNumericCalc" */ "@/components/baseInputNumericCalc.vue"),
        VouchersTable: () => import(/* webpackChunkName: "VouchersTable" */ "@/components/vouchersTable/v1/VouchersTable.vue"),
        DateInputField: () => import(/* webpackChunkName: "DateInputField" */ "@/components/DateInputField.vue"),
        CreateSupplier: () => import(/* webpackChunkName: "CreateSupplier" */ "./createSupplier.vue"),
        UpdateSupplier: () => import(/* webpackChunkName: "UpdateSupplier" */ "./updateSupplier.vue"),
        UpdateDefaultAccount: () => import(/* webpackChunkName: "UpdateDefaultAccount" */ "./updateDefaultAccount.vue"),
        AccrualModal: () => import(/* webpackChunkName: "AccrualModal" */ "../accrualModal.vue"),
        SupplierSearch: () => import(/* webpackChunkName: "SupplierSearch" */ "./supplierSearch"),
        MLConfidenceTag: () => import(/* webpackChunkName: "MLConfidenceTag" */ "./mlConfidenceTag.vue"),
    },

    mounted() {
        this.setOriginalValues();
    },

    async created() {
        await this.getClients();
        await this.getSuppliers();
        if (!this.isSekInvoice) {
            this.currenciesList = await Api.getCurrenciesAsync();
            this.selectedCurrency = this.currenciesList.filter(c => c.value.toUpperCase() == this.tempSupplierInvoice.currency.toUpperCase())[0];
        }
        this.init();
        this.checkInvoiceDate(this.tempSupplierInvoice.invoiceDate);
        this.checkDueDate(this.tempSupplierInvoice.dueDate);
        if (this.supplierInvoice.status != Enum.supplierInvoiceStatusList.Utkast && this.$flags.Accruals) this.checkifPeriodize();
        this.fillPreDefinedVoucherRows();
        this.mlResults = await Api.getMLResult(this.supplierInvoice.blobId);
        // console.log(
        //     "🚀 ~ file: supplierInvoiceForm.vue ~ line 95 ~ created ~ data",
        //     this.mlResults,
        //     this.mlResults.find(item => item.mlResultSource == 3),
        // );
    },

    methods: {
        updateAccrualDates() {
            if (!this.supplierInvoice.isAccrual) {
                this.tempSupplierInvoice.startDate = null;
                this.tempSupplierInvoice.endDate = null;
                this.voucherRows.forEach(element => {
                    element.startDate = null;
                    element.endDate = null;
                });
            }
        },

        applyBilmoms() {
            if (this.tempSupplierInvoice.carVat) {
                this.tempSupplierInvoice.totalVat = this.supplierInvoice.originalVatAmount / 2;
                this.tempSupplierInvoice.totalNetAmount = this.supplierInvoice.originalNetAmount + this.tempSupplierInvoice.originalVatAmount / 2;
            } else {
                this.tempSupplierInvoice.totalVat = this.supplierInvoice.originalVatAmount;
                this.tempSupplierInvoice.totalNetAmount = this.supplierInvoice.originalNetAmount;
            }
            this.updateVoucherVatAmount();
            this.updateVoucherNetAmount();
        },

        async showAccruals({ account, debit, credit, startDate, endDate }) {
            this.accrualModal = true;

            this.selectAccruals = { costAccount: account, amount: debit != 0 ? debit : credit, invoiceDate: this.tempSupplierInvoice.invoiceDate, startDate: startDate, stopDate: endDate };
            this.accrualModalData = await Api.getAccruals({
                costAccount: account,
                amount: debit != 0 ? debit : credit,
                invoiceDate: this.tempSupplierInvoice.invoiceDate,
                startDate: startDate,
                stopDate: endDate,
            });
        },

        init() {
            if (this.supplierInvoice.status != Enum.supplierInvoiceStatusList.Exported && this.supplierInvoice.status != Enum.supplierInvoiceStatusList.NotSet) {
                this.tempSupplierInvoice.totalNetAmount = this.supplierInvoice.originalNetAmount;
                this.tempSupplierInvoice.totalVat = this.supplierInvoice.originalVatAmount;
            } else {
                this.tempSupplierInvoice.totalGrossAmount = this.convertToSek(this.tempSupplierInvoice.internationalGrossAmount);
                this.tempSupplierInvoice.totalNetAmount = this.convertToSek(this.supplierInvoice.originalNetAmount);
                this.tempSupplierInvoice.totalVat = this.convertToSek(this.supplierInvoice.originalVatAmount);
            }
        },

        checkifPeriodize() {
            if (this.tempSupplierInvoice.invoiceDate && this.tempSupplierInvoice.startDate && this.tempSupplierInvoice.endDate) {
                let startDateDiff = moment(this.tempSupplierInvoice.invoiceDate).isSame(this.tempSupplierInvoice.startDate, "month");
                let endDateDiff = moment(this.tempSupplierInvoice.invoiceDate).isSame(this.tempSupplierInvoice.endDate, "month");
                if (!startDateDiff && !endDateDiff) {
                    this.supplierInvoice.isAccrual = true;
                }
            } else if (this.tempSupplierInvoice.invoiceDate && this.tempSupplierInvoice.startDate) {
                let startDateDiff = moment(this.tempSupplierInvoice.invoiceDate).isSame(this.tempSupplierInvoice.startDate, "month");
                if (!startDateDiff) {
                    this.supplierInvoice.isAccrual = true;
                }
            } else if (this.tempSupplierInvoice.invoiceDate && this.tempSupplierInvoice.endDate) {
                let endDateDiff = moment(this.tempSupplierInvoice.invoiceDate).isSame(this.tempSupplierInvoice.endDate, "month");
                if (!endDateDiff) {
                    this.supplierInvoice.isAccrual = true;
                }
            } else {
                this.supplierInvoice.isAccrual = false;
            }

            if (this.supplierInvoice.isAccrual) {
                this.assignAccrualsToVoucher();
            } else {
                this.removeAccrualsToVoucher();
            }

            if (this.tempSupplierInvoice.startDate != null) {
                this.removeCustomValidationError("StartDateError");
            }

            if (this.tempSupplierInvoice.endDate != null) {
                this.removeCustomValidationError("EndDateError");
            }
        },

        removeAccrualsToVoucher() {
            this.voucherRows.forEach(function(element) {
                element.readOnly === false ? (element.startDate = null) : null;
                element.readOnly === false ? (element.endDate = null) : null;
            });
        },

        assignAccrualsToVoucher() {
            this.voucherRows.forEach(element => {
                element.readOnly === false ? (element.startDate = this.formatDate(this.tempSupplierInvoice.startDate)) : null;
                element.readOnly === false ? (element.endDate = this.formatDate(this.tempSupplierInvoice.endDate)) : null;
            });
        },

        addDefaultAccount() {
            let startDate = this.supplierInvoice.isAccrual ? this.formatDate(this.tempSupplierInvoice.startDate) : null;
            let endDate = this.supplierInvoice.isAccrual ? this.formatDate(this.tempSupplierInvoice.endDate) : null;
            if (this.supplierInvoice.supplierAccountValues) {
                const found = this.supplierInvoice.supplierAccountValues.filter(el => el.value === this.defaultAccount);
                if (found.length < 1) {
                    let index_defaultAccount = this.voucherRows.findIndex(row => row.account == parseInt(this.defaultAccount));
                    if (index_defaultAccount < 0 && this.defaultAccount) {
                        this.addDebitVoucherRow(this.defaultAccount, this.tempSupplierInvoice.totalNetAmount, false, startDate, endDate);
                        this.reassembleVoucherArray();
                    }
                }
            } else {
                let index_defaultAccount = this.voucherRows.findIndex(row => row.account == parseInt(this.defaultAccount));
                if (index_defaultAccount < 0 && this.defaultAccount) {
                    this.addDebitVoucherRow(this.defaultAccount, this.tempSupplierInvoice.totalNetAmount, false, startDate, endDate);
                    this.reassembleVoucherArray();
                }
            }
        },

        inputInvoiceDate($event) {
            this.tempSupplierInvoice.invoiceDate = $event;
            this.checkInvoiceDate(this.tempSupplierInvoice.invoiceDate);
            this.checkifPeriodize();
        },

        inputDueDate($event) {
            this.tempSupplierInvoice.dueDate = $event;
            this.checkDueDate(this.tempSupplierInvoice.dueDate);
        },

        removeVatVoucherRows() {
            if (this.voucherRows) {
                var index_2641 = this.voucherRows.findIndex(row => row.account === 2641);
                if (index_2641 > -1) {
                    this.voucherRows.splice(index_2641, 1);
                }

                var index_2614 = this.voucherRows.findIndex(row => row.account === 2614);
                if (index_2614 > -1) {
                    this.voucherRows.splice(index_2614, 1);
                }

                var index_2645 = this.voucherRows.findIndex(row => row.account === 2645);
                if (index_2645 > -1) {
                    this.voucherRows.splice(index_2645, 1);
                }
            }
        },

        setVatVoucherRows(value) {
            this.removeVatVoucherRows();

            if (
                (value[0].value != Enum.supplierCountry.Sweden && value[2].value == Enum.momsNumber.Reported) ||
                (value[0].value == Enum.supplierCountry.Sweden && value[1].value == Enum.reverseConstruction.Yes)
            ) {
                this.addCreditVoucherRow(2614, this.reverseVat, true);
                this.addDebitVoucherRow(2645, this.reverseVat, true);
            } else {
                if (this.tempSupplierInvoice.totalVat != 0) this.addDebitVoucherRow(2641, this.tempSupplierInvoice.totalVat, true);
            }

            this.reassembleVoucherArray();
        },

        reassembleVoucherArray() {
            let emptyRow = this.voucherRows.filter(el => el.readOnly === false && el.account === null);
            let editableItems = this.voucherRows.filter(el => el.readOnly === false && el.account != null);
            this.voucherRows = this.voucherRows.filter(el => el.readOnly !== false);
            const arr2 = [...this.voucherRows, ...editableItems, ...emptyRow];
            this.voucherRows = arr2;
        },

        getRadioValues(value) {
            this.radioValues = value;
            if (value[0].value == Enum.supplierCountry.Sweden && value[1].value == Enum.reverseConstruction.No) {
                this.isReported = false;
            }
            if (value[2].value == Enum.momsNumber.Reported || value[1].value == Enum.reverseConstruction.Yes || value[2].value == Enum.momsNumber.NotReported) {
                if (
                    value[1].value == Enum.reverseConstruction.Yes ||
                    ((value[2].value == Enum.momsNumber.Reported || value[2].value == Enum.momsNumber.NotReported) &&
                        this.tempSupplierInvoice.totalNetAmount != this.tempSupplierInvoice.totalGrossAmount)
                ) {
                    var tempAddAmount = 0;
                    tempAddAmount = parseFloat(this.tempSupplierInvoice.totalNetAmount).x100() + parseFloat(this.tempSupplierInvoice.totalVat).x100();

                    tempAddAmount = tempAddAmount / 100;

                    this.tempSupplierInvoice.totalNetAmount = tempAddAmount;
                    this.tempSupplierInvoice.totalVat = 0;
                }
                if (value[3].value == Enum.vatRate.TwentyFive || value[3].value == Enum.vatRate.Twelve || value[3].value == Enum.vatRate.Six) {
                    this.isReported = true;
                    this.calculatePercentage(value[3].value);
                }
            } else if (value[2].value == Enum.momsNumber.NotReported) {
                this.isReported = false;
                this.tempSupplierInvoice.totalNetAmount = this.convertToSek(this.supplierInvoice.originalNetAmount);
                this.tempSupplierInvoice.totalVat = this.convertToSek(this.supplierInvoice.originalVatAmount);
            }

            this.supplierRadioValues.supplierCountry = value[0].value;
            this.supplierRadioValues.reverseConstruction = value[1].value;
            this.supplierRadioValues.momsNumber = value[2].value;
            this.supplierRadioValues.vatRate = value[3].value;
            this.setVatVoucherRows(value);
        },

        removeVoucherRow(index) {
            let newItems = this.voucherRows;
            const lastRow = index === newItems.length - 1;
            newItems.splice(index, 1);
            this.voucherRows = newItems;

            if (this.voucherRows.filter(item => !item.readOnly).length === 0 || lastRow) {
                this.voucherRows.push({
                    id: Math.random(),
                    account: null,
                    debit: 0,
                    credit: 0,
                    text: "",
                    readOnly: false,
                    startDate: this.supplierInvoice.isAccrual ? this.formatDate(this.tempSupplierInvoice.startDate) : null,
                    endDate: this.supplierInvoice.isAccrual ? this.formatDate(this.tempSupplierInvoice.endDate) : null,
                });
            }
        },

        updateVoucherGrossAmount() {
            this.$set(this.voucherRows[0], "credit", parseFloat(this.tempSupplierInvoice.totalGrossAmount));
        },

        updateVoucherVatAmount() {
            var vat = isNaN(this.tempSupplierInvoice.totalVat) || this.tempSupplierInvoice.totalVat == "" ? 0 : parseFloat(this.tempSupplierInvoice.totalVat);
            var index = this.voucherRows.findIndex(row => row.account === 2641);
            if (index > -1) {
                this.voucherRows.splice(index, 1);
            }
            if (vat != 0) {
                let obj_2641 = {
                    id: Math.random(),
                    account: 2641,
                    debit: vat > 0 ? vat : 0,
                    credit: vat < 0 ? -vat : 0,
                    text: "",
                    readOnly: true,
                };
                this.voucherRows.splice(1, 0, obj_2641);
            }
            this.reassembleVoucherArray();
        },

        updateVoucherReminderFee() {
            let index_6991 = this.voucherRows.findIndex(row => row.account === 6991);
            if (index_6991 > -1) {
                this.voucherRows.splice(index_6991, 1);
            }
            if (parseFloat(this.tempSupplierInvoice.reminderFee) !== 0 && !isNaN(parseFloat(this.tempSupplierInvoice.reminderFee)) && this.tempSupplierInvoice.reminderFee != "") {
                this.addDebitVoucherRow(6991, this.tempSupplierInvoice.reminderFee, true, "", "");
            }
            this.reassembleVoucherArray();
        },

        updateVoucherRounding() {
            let index_3740 = this.voucherRows.findIndex(row => row.account === 3740);
            if (index_3740 > -1) {
                this.voucherRows.splice(index_3740, 1);
            }

            if (parseFloat(this.tempSupplierInvoice.rounding) !== 0 && !isNaN(this.tempSupplierInvoice.rounding) && this.tempSupplierInvoice.rounding != "") {
                let obj_3740 = {
                    id: Math.random(),
                    account: 3740,
                    debit: this.isMinusRounding ? 0 : parseFloat(this.tempSupplierInvoice.rounding),
                    credit: this.isMinusRounding ? Math.abs(parseFloat(this.tempSupplierInvoice.rounding)) : 0,
                    text: "",
                    readOnly: true,
                };
                this.voucherRows.splice(1, 0, obj_3740);
            }
            this.reassembleVoucherArray();
        },

        addCreditVoucherRow(account, amount, readOnly) {
            let objToBeAdded = {
                id: Math.random(),
                account: parseInt(account),
                debit: 0,
                credit: parseFloat(amount),
                text: "",
                readOnly: readOnly,
                startDate: this.supplierInvoice.isAccrual ? this.formatDate(this.tempSupplierInvoice.startDate) : null,
                endDate: this.supplierInvoice.isAccrual ? this.formatDate(this.tempSupplierInvoice.endDate) : null,
            };
            this.voucherRows.push(objToBeAdded);
        },

        addDebitVoucherRow(account, amount, readOnly, startDate = null, endDate = null) {
            let objToBeAdded = {
                id: Math.random(),
                account: parseInt(account),
                debit: parseFloat(amount),
                credit: 0,
                text: "",
                readOnly: readOnly,
                startDate: startDate,
                endDate: endDate,
            };
            this.voucherRows.push(objToBeAdded);
        },

        updateVoucherNetAmount() {
            if (this.radioValues != undefined) {
                this.getRadioValues(this.radioValues);
            }
            if (this.defaultAccount != undefined) {
                var index_defaultAccount = this.voucherRows.findIndex(row => row.account === parseInt(this.defaultAccount));
                if (index_defaultAccount > -1) this.$set(this.voucherRows[index_defaultAccount], "debit", parseFloat(this.tempSupplierInvoice.totalNetAmount));
            }
        },

        fillPreDefinedVoucherRows() {
            this.$set(this.voucherRows[0], "credit", parseFloat(this.tempSupplierInvoice.totalGrossAmount));

            if (this.supplierInvoice.supplierAccountValues != undefined) {
                this.supplierInvoice.supplierAccountValues.forEach(element => {
                    let preDefinedRows = {
                        id: Math.random(),
                        account: parseInt(element.value),
                        debit: parseFloat(element.netAmount) >= 0 ? parseFloat(element.netAmount) : 0,
                        credit: element.netAmount < 0 ? Math.abs(element.netAmount) : 0,
                        text: "",
                        readOnly: false,
                        startDate: this.formatDate(element.startDate),
                        endDate: this.formatDate(element.endDate),
                    };
                    this.voucherRows.push(preDefinedRows);
                });
            }

            if (this.voucherRows.filter(item => item.account === null && !item.readOnly).length === 0) {
                this.voucherRows.push({
                    id: Math.random(),
                    account: null,
                    debit: 0,
                    credit: 0,
                    text: "",
                    readOnly: false,
                    startDate: this.supplierInvoice.isAccrual ? this.formatDate(this.tempSupplierInvoice.startDate) : null,
                    endDate: this.supplierInvoice.isAccrual ? this.formatDate(this.tempSupplierInvoice.endDate) : null,
                });
            }
            this.updateVoucherRounding();
            this.updateVoucherReminderFee();
        },

        createNewItemComponent() {
            this.voucherRows.push({
                id: Math.random(),
                account: null,
                debit: 0,
                credit: 0,
                text: "",
                readOnly: false,
                startDate: this.supplierInvoice.isAccrual ? this.formatDate(this.tempSupplierInvoice.startDate) : null,
                endDate: this.supplierInvoice.isAccrual ? this.formatDate(this.tempSupplierInvoice.endDate) : null,
            });
        },

        updateVoucherRow({ index, account, debit, credit, comment, startDate, endDate }) {
            this.voucherRows[index].account = account;
            this.voucherRows[index].debit = debit;
            this.voucherRows[index].credit = credit;
            this.voucherRows[index].text = comment;
            this.voucherRows[index].startDate = startDate;
            this.voucherRows[index].endDate = endDate;
        },

        async refreshSupplierList(value) {
            if (this.createSupplier) {
                this.isNewSupplier = true;
            }
            this.createSupplier = false;
            this.updateSupplier = false;
            await this.getSuppliers(value.value, value.name);
        },

        checkDueDate(event) {
            if (event === undefined || event === "" || event === "Invalid date" || event === null) {
                this.dueDateWarning = "Förfallodatum saknas";
                return;
            }
            var dueDate = moment(event);
            var invoiceDate = moment(this.tempSupplierInvoice.invoiceDate);
            var daysDiff = dueDate.diff(invoiceDate, "days");
            if (dueDate < invoiceDate) {
                this.dueDateWarning = "Förfallodag kan inte vara mindre än fakturadatum";
            } else if (daysDiff > 40) {
                this.dueDateWarning = "Stor skillnad mellan fakturadatum";
            } else {
                this.dueDateWarning = undefined;
            }
        },

        checkInvoiceDate(event) {
            if (event === undefined || event === "" || event === "Invalid date" || event === null) {
                this.invoiceDateWarning = "Fakturadatum saknas";
                return;
            }
            var invoiceDate = moment(event);
            var oneMonthBefore = moment().subtract(1, "months");
            var futureDateValidate = moment().add(5, "days");
            if (invoiceDate < oneMonthBefore) {
                this.invoiceDateWarning = "Fakturan är äldre än en månad";
            } else if (invoiceDate > futureDateValidate) {
                this.invoiceDateWarning = "kontrollera datumet";
            } else this.invoiceDateWarning = undefined;

            this.checkDueDate(this.tempSupplierInvoice.dueDate);
        },

        setOriginalValues() {
            if (!this.tempSupplierInvoice.ocrNumber) {
                this.tempSupplierInvoice.ocrNumber = this.tempSupplierInvoice.invoiceNumber;
            }
            this.tempSupplierInvoice.invoiceDate = this.formatDate(this.tempSupplierInvoice.invoiceDate);
            this.tempSupplierInvoice.dueDate = this.formatDate(this.tempSupplierInvoice.dueDate);
        },

        convertValues() {
            this.tempSupplierInvoice.totalGrossAmount = this.convertToSek(this.tempSupplierInvoice.internationalGrossAmount);
            this.tempSupplierInvoice.totalNetAmount = this.convertToSek(this.supplierInvoice.originalNetAmount);
            this.tempSupplierInvoice.totalVat = this.convertToSek(this.supplierInvoice.originalVatAmount);
            if (this.radioValues != undefined) {
                this.getRadioValues(this.radioValues);
            }
        },

        setAccountValues(value) {
            if (value[0] && value[0].value != 0) {
                if (this.supplierAccountValues.length == 0) {
                    this.supplierAccountValues.push(value);
                } else if (this.supplierAccountValues[0]) {
                    this.supplierAccountValues[0].forEach(tab => {
                        value[0].value == tab.value ? (tab.netAmount = value[0].netAmount) : tab.netAmount;
                    });
                }
            }
        },

        getCurrencies(currencyValue) {
            if (currencyValue != "SEK") {
                if (this.currenciesList) {
                    this.selectedCurrency = this.currenciesList.filter(c => c.value.toUpperCase() === currencyValue.toUpperCase())[0];
                }
            }
        },

        convertToSek(amount) {
            if (this.selectedCurrency && this.selectedCurrency.value != "SEK") {
                var tempAmount = amount * parseFloat(this.selectedCurrency.amount) * 100;
                tempAmount = tempAmount / 100;
                return parseFloat(tempAmount).toFixed(2);
            }
            return parseFloat(amount).toFixed(2);
        },

        getSupplierSelectedValue(value) {
            this.updateSupplier = false;
            this.defaultAccount = false;
            if (value === "" || value === null || value === undefined) {
                this.supplierSearchOption.selected = {};
                this.addError("Leverantör saknas");
            } else {
                this.removeError("Leverantör saknas");
                this.supplierSearchOption.selected = value;
                this.supplierAccountValues = [];
                this.getDefaultAccount();
            }
        },

        updateDefaultAccounts(value) {
            this.supplierSearchOption.selected.individualDefaultAccount = value.privateDefaultAccount;
            this.supplierSearchOption.selected.limitedDefaultAccount = value.ltdDefaultAcc;
            this.updateAccount = false;
        },

        removeError(value) {
            this.$validator.errors.remove(value, value);
        },

        addError(value) {
            this.errors.add({
                scope: value,
                field: value,
                msg: value,
            });
        },

        getClientSelectedValue(value) {
            if (value === "" || value === undefined) {
                this.addError("Klient saknas");
                this.clientSearchOption.selected = {};
            } else {
                this.removeError("Klient saknas");
                this.clientSearchOption.selected = value;
                if (value.companyType == Enum.companyType.EnskildFirma) {
                    this.selectedCompanyType = Enum.AccountCompanyType.EnskildFirma;
                } else if (value.companyType == Enum.companyType.Aktiebolag) {
                    this.selectedCompanyType = Enum.AccountCompanyType.Aktiebolag;
                } else if (value.companyType == Enum.companyType.Handelsbolag) {
                    this.selectedCompanyType = Enum.AccountCompanyType.Handelsbolag;
                }
                this.getDefaultAccount();
            }
        },

        async getClients() {
            this.clientSearchOption = await Api.getSelectedClients(this.tempSupplierInvoice.clientName);
            if (this.supplierInvoice.clientInfo) {
                this.clientSearchOption.selected = this.supplierInvoice.clientInfo;
            }
            if (this.clientSearchOption && this.clientSearchOption.selected) {
                this.getClientSelectedValue(this.clientSearchOption.selected);
            }
        },

        async getSuppliers(supplierValue = "", supplierName = "") {
            var suppliers = {
                Name: supplierName || this.tempSupplierInvoice.supplierName,
                OrgNo: this.tempSupplierInvoice.supplierOrgNo,
                Email: this.tempSupplierInvoice.supplierEmail,
                Country: this.tempSupplierInvoice.supplierCountry,
                Value: supplierValue,
            };
            this.rossumFoundSupplierName = this.tempSupplierInvoice.supplierName;
            this.supplierSearchOption = await Api.getSuppliers(suppliers);
            this.getSupplierSelectedValue(this.supplierSearchOption.selected);
            this.getDefaultAccount();
        },

        async getDefaultAccount() {
            if (this.supplierSearchOption.selected) {
                if (this.clientSearchOption.selected && (this.clientSearchOption.selected.value != null || this.clientSearchOption.selected.value != undefined)) {
                    if (this.selectedCompanyType == Enum.AccountCompanyType.EnskildFirma || this.selectedCompanyType == Enum.AccountCompanyType.Handelsbolag) {
                        if (this.supplierSearchOption.selected.individualDefaultAccount) {
                            this.defaultAccount = this.supplierSearchOption.selected.individualDefaultAccount;
                        } else {
                            this.findDefaultAccount();
                        }
                    } else if (this.selectedCompanyType == Enum.AccountCompanyType.Aktiebolag) {
                        if (this.supplierSearchOption.selected.limitedDefaultAccount) {
                            this.defaultAccount = this.supplierSearchOption.selected.limitedDefaultAccount;
                        } else {
                            this.findDefaultAccount();
                        }
                    }
                }
            }
            if (this.supplierInvoice.status == Enum.supplierInvoiceStatusList.Exported || this.supplierInvoice.status == Enum.supplierInvoiceStatusList.NotSet) {
                this.addDefaultAccount();
            }
        },

        async findDefaultAccount() {
            var searchAccountObj = {
                supplierId: this.supplierSearchOption.selected.value,
                clientId: this.clientSearchOption.selected.value,
                companyType: this.selectedCompanyType,
            };
            var data = await Api.postSupplierAccount(searchAccountObj);
            this.defaultAccount = data;
        },

        calculatePercentage(value) {
            var tempNetAmount = parseFloat(this.tempSupplierInvoice.totalNetAmount).x100();
            if (value == Enum.vatRate.TwentyFive) {
                this.reverseVat = (tempNetAmount * 25) / 100;
            } else if (value == Enum.vatRate.Twelve) {
                this.reverseVat = (tempNetAmount * 12) / 100;
            } else if (value == Enum.vatRate.Six) {
                this.reverseVat = (tempNetAmount * 6) / 100;
            }
            this.reverseVat = parseFloat(this.reverseVat / 100).toFixed(2);
        },

        formatDate(date) {
            return date
                ? moment
                      .utc(date)
                      .local()
                      .format("YYYY-MM-DD")
                : "";
        },

        removeCustomValidationError(value) {
            this.customError = this.customError.filter(e => e.name != value);
        },

        addCustomValidationError(value, errorMessage) {
            var valueIsPresent = this.customError.filter(e => e.name == value);
            if (valueIsPresent.length == 0) {
                var addEmptyRow = {
                    name: value,
                    errorMessage: errorMessage,
                };
                this.customError.push(addEmptyRow);
            }
        },

        customValidateForm(value) {
            if (
                (this.clientSearchOption.length === 0 && !this.clientSearchOption.selected) ||
                Object.keys(this.clientSearchOption.selected).length === 0 ||
                !this.clientSearchOption.selected["name"]
            ) {
                this.scrollToTop();
                this.addCustomValidationError("ClientError", "Klient saknas");
            } else {
                this.removeCustomValidationError("ClientError");
            }

            if (!this.supplierSearchOption.selected || Object.keys(this.supplierSearchOption.selected).length === 0) {
                this.scrollToTop();
                this.addCustomValidationError("SupplierError", "Leverantör saknas");
            } else {
                this.removeCustomValidationError("SupplierError");
            }

            if ((this.tempSupplierInvoice.startDate === null || this.tempSupplierInvoice.startDate === "") && this.supplierInvoice.isAccrual) {
                this.addCustomValidationError("StartDateError", "Vänligen välj startdatum");
            } else {
                this.removeCustomValidationError("StartDateError");
            }
            if ((this.tempSupplierInvoice.endDate === null || this.tempSupplierInvoice.endDate === "") && this.supplierInvoice.isAccrual) {
                this.addCustomValidationError("EndDateError", "Vänligen välj slutdatum");
            } else {
                this.removeCustomValidationError("EndDateError");
            }

            if (this.supplierRadioValues.supplierCountry == undefined || this.supplierRadioValues.supplierCountry == -1) {
                this.addCustomValidationError("RadioButtonsError", "Vänligen välj inköpsland");
            } else {
                this.removeCustomValidationError("RadioButtonsError");
            }

            if (value != undefined && value == Enum.CreditorInvoiceStatus.AdminNekad && (this.supplierInvoice.comments == null || this.supplierInvoice.comments == "")) {
                this.addCustomValidationError("Deny", "Vänligen ange anledningen att förneka");
            } else {
                this.removeCustomValidationError("Deny");
            }

            if (
                this.tempSupplierInvoice.invoiceDate === undefined ||
                this.tempSupplierInvoice.invoiceDate === "" ||
                this.tempSupplierInvoice.invoiceDate === "Invalid date" ||
                this.tempSupplierInvoice.invoiceDate === null
            ) {
                this.scrollToTop();
                this.addCustomValidationError("InvoiceDate", "Vänligen ange fakturadatum");
            } else {
                this.removeCustomValidationError("InvoiceDate");
            }

            if (
                this.tempSupplierInvoice.dueDate === undefined ||
                this.tempSupplierInvoice.dueDate === "" ||
                this.tempSupplierInvoice.dueDate === "Invalid date" ||
                this.tempSupplierInvoice.dueDate === null
            ) {
                this.scrollToTop();
                this.addCustomValidationError("DueDate", "Vänligen ange förfallodatum");
            } else {
                this.removeCustomValidationError("DueDate");
            }

            if (this.cantCreateVoucher) {
                this.addCustomValidationError("CantCreateVoucher", "kupongskillnaden måste vara 0");
            } else {
                this.removeCustomValidationError("CantCreateVoucher");
            }
        },

        bookInvoice() {
            this.$store.commit("supplierInvoice/setSendBtnLoading", true);
            this.saveObjectChanges(Enum.CreditorInvoiceStatus.Attesteras);
        },

        deleteInvoice() {
            this.$store.commit("supplierInvoice/setDeleteBtnLoading", true);
            this.$emit("deleteInvoice");
        },

        denyInvoice() {
            this.saveObjectChanges(Enum.CreditorInvoiceStatus.AdminNekad);
        },

        applyEmptyValues() {
            if (this.supplierInvoice.tempSupplierInvoice.rounding == "") this.supplierInvoice.tempSupplierInvoice.rounding = 0.0;
            if (this.supplierInvoice.tempSupplierInvoice.totalGrossAmount == "") this.supplierInvoice.tempSupplierInvoice.totalGrossAmount = 0.0;
            if (this.supplierInvoice.tempSupplierInvoice.totalNetAmount == "") this.supplierInvoice.tempSupplierInvoice.totalNetAmount = 0.0;
            if (this.supplierInvoice.tempSupplierInvoice.totalVat == "") this.supplierInvoice.tempSupplierInvoice.totalVat = 0.0;

            if (this.supplierInvoice.tempSupplierInvoice.invoiceDate == "Invalid date") this.supplierInvoice.tempSupplierInvoice.invoiceDate = null;
            if (this.supplierInvoice.tempSupplierInvoice.dueDate == "Invalid date") this.supplierInvoice.tempSupplierInvoice.dueDate = null;

            this.supplierInvoice.supplierRadioValues = this.supplierRadioValues;
            this.supplierInvoice.clientInfo = this.clientSearchOption.selected;
            this.supplierInvoice.creditorInfo = this.supplierSearchOption.selected;
            this.tempSupplierInvoice.currency = this.selectedCurrency.value;
        },

        async createReceipt() {
            var searchFieldValidation = this.errors.items.find(option => option.field == "search");
            this.$nextTick(() => {
                this.errors.clear();
                this.$nextTick(() => {
                    this.$validator.reset();
                });
            });
            var fieldsRequired;
            if (this.supplierInvoice.tempSupplierInvoice.invoiceNumber == "" || this.supplierInvoice.tempSupplierInvoice.invoiceNumber == null) {
                fieldsRequired = "Faturanummer";
            } else if (
                this.supplierInvoice.tempSupplierInvoice.dueDate == "Invalid date" ||
                this.supplierInvoice.tempSupplierInvoice.dueDate == null ||
                this.supplierInvoice.tempSupplierInvoice.dueDate == ""
            ) {
                fieldsRequired = "Förfallodatum";
            } else if (this.supplierInvoice.tempSupplierInvoice.totalGrossAmount == "" || this.supplierInvoice.tempSupplierInvoice.totalGrossAmount == null) {
                fieldsRequired = "Belopp";
            }

            if (
                (searchFieldValidation != undefined && searchFieldValidation.field != undefined) ||
                this.clientSearchOption.selected.value == null ||
                this.supplierSearchOption.selected.value == null
            ) {
                var errorField = "Klient/ Leverantör ";
                this.clearButtonLoadings();
                await this.$dialog.html().alert(`${errorField} field is required.`);
            } else if (fieldsRequired != "" && fieldsRequired != null && fieldsRequired != undefined) {
                await this.$dialog.html().alert(`${fieldsRequired} field is required.`);
                this.clearButtonLoadings();
            } else {
                this.applyEmptyValues();
                this.createObjectForSaving();
                this.$emit("createReceipt", this.supplierInvoice);
            }
        },

        saveInvoiceData() {
            if (this.supplierInvoice.isCreditNote) {
                this.$nextTick(() => {
                    this.errors.clear();
                    this.$nextTick(() => {
                        this.$validator.reset();
                    });
                });

                this.applyEmptyValues();

                var searchFieldValidation = this.errors.items.find(option => option.field == "search");
                if (searchFieldValidation == undefined) {
                    this.saveCreditNote();
                }
            } else {
                this.saveObjectChanges(Enum.CreditorInvoiceStatus.Utkast);
            }
        },

        saveCreditNote() {
            this.$emit("saveSupplierInvoice", this.supplierInvoice, 0);
        },

        createObjectForSaving() {
            if (this.isSekInvoice) this.tempSupplierInvoice.currency = this.selectedCurrency.value;
            this.tempSupplierInvoice.internationalGrossAmount = this.tempSupplierInvoice.internationalGrossAmount;
            this.supplierInvoice.originalNetAmount = this.supplierInvoice.originalNetAmount;

            this.supplierInvoice.originalVatAmount = this.supplierInvoice.originalVatAmount;
            this.supplierInvoice.supplierRadioValues = this.supplierRadioValues;
            this.supplierInvoice.clientInfo = this.clientSearchOption.selected;
            this.supplierInvoice.creditorInfo = this.supplierSearchOption.selected;
            this.supplierInvoice.creditorInfo.isNew = this.isNewSupplier;
            this.voucherRows = this.voucherRows.filter(row => {
                return row.debit !== 0 || row.credit !== 0;
            });

            this.supplierInvoice.voucherTable = this.voucherRows;
            this.tempSupplierInvoice.currency = this.selectedCurrency.value;

            this.supplierInvoice.supplierAccountValues = [];
            if (this.supplierAccountValues[0]) {
                for (var i = 0; i < this.supplierAccountValues[0].length; i++) {
                    this.supplierInvoice.supplierAccountValues.push(this.supplierAccountValues[0][i]);
                }
            }
        },

        scrollToTop() {
            window.scrollTo({ top: 0, behavior: "smooth" });
        },

        saveObjectChanges(status) {
            this.customValidateForm(status);
            this.$validator.validateAll().then(isValid => {
                if (isValid && this.errors.items.length == 0 && this.customError.length <= 0 && !this.cantCreateVoucher) {
                    this.applyEmptyValues();
                    this.createObjectForSaving();
                    if (status == Enum.CreditorInvoiceStatus.Utkast) {
                        this.$emit("saveSupplierInvoice", this.supplierInvoice, Enum.CreditorInvoiceStatus.Utkast);
                    } else if (status == Enum.CreditorInvoiceStatus.AdminNekad) this.$emit("denySupplierInvoice", this.supplierInvoice, Enum.CreditorInvoiceStatus.AdminNekad);
                    else if (status == Enum.CreditorInvoiceStatus.Attesteras) this.$emit("bookInvoice", this.supplierInvoice);
                } else {
                    this.clearButtonLoadings();
                }
            });
        },

        clearButtonLoadings() {
            this.$store.commit("supplierInvoice/clearDeleteBtnLoading");
            this.$store.commit("supplierInvoice/clearSaveBtnLoading");
            this.$store.commit("supplierInvoice/clearDenyBtnLoading");
            this.$store.commit("supplierInvoice/clearSendBtnLoading");
            this.$store.commit("supplierInvoice/clearReceiptBtnLoading");
        },

        isParsed(value) {
            let parsed = parseInt(value);
            if (isNaN(parsed)) return 0;
            return parsed;
        },

        // azureMlResult() {
        //     return this.mlResults.find(item => item.mlResultSource === 3);
        // },
    },

    computed: {
        ...mapState({
            accountsInfo: state => state.supplierInvoice.accountsInfo,
        }),

        azureMlResult() {
            return this.mlResults.find(item => item.mlResultSource === 3);
        },

        tyMlResult() {
            return this.mlResults.find(item => item.mlResultSource === 4);
        },

        rossumMlResult() {
            return this.mlResults.find(item => item.mlResultSource === 1);
        },

        selectedSupplierName() {
            return this.supplierSearchOption.selected != undefined ? this.supplierSearchOption.selected.name : "";
        },

        voucherError() {
            return this.customError.find(e => e.name === "CantCreateVoucher");
        },

        startDateError() {
            return this.customError.find(e => e.name === "StartDateError");
        },

        endDateError() {
            return this.customError.find(e => e.name === "EndDateError");
        },

        isMinusRounding() {
            return this.tempSupplierInvoice.rounding < 0 ? true : false;
        },

        accountPlanItems() {
            if (this.selectedCompanyType == Enum.companyType.Aktiebolag) return this.stockCompanyAccountPlanItems;
            else return this.privateFirmAccountPlanItems;
        },

        stockCompanyAccountPlanItems() {
            var stockAccountPlans = this.accountsInfo.filter(a => a.companyType == 1)[0].accounts;
            return stockAccountPlans.map(item => {
                return {
                    name: item.name,
                    value: item.value,
                };
            });
        },

        privateFirmAccountPlanItems() {
            var privateFirmAccountPlans = this.accountsInfo.filter(a => a.companyType == 2)[0].accounts;
            return privateFirmAccountPlans.map(item => {
                return {
                    name: item.name,
                    value: item.value,
                };
            });
        },

        internationalRequiredRule() {
            if (!this.isSekInvoice) {
                return "required";
            }
            return "";
        },

        currencyDropDownRule() {
            if (!this.selectedCurrency) {
                return "required";
            }
            return "";
        },

        residenceForTax() {
            var tempresidenceForTax = "";
            if (this.tempSupplierInvoice != null) {
                if (this.tempSupplierInvoice.supplierVatNo != null) {
                    tempresidenceForTax = this.tempSupplierInvoice.supplierVatNo.substring(0, 2);
                } else if (this.tempSupplierInvoice.accountNumber != null) {
                    tempresidenceForTax = this.tempSupplierInvoice.accountNumber.substring(0, 2);
                }
            } else return "";
            return tempresidenceForTax;
        },

        tempSupplierInvoice() {
            return this.supplierInvoice.tempSupplierInvoice;
        },

        isSekInvoice() {
            if (this.tempSupplierInvoice.currency.trim().toUpperCase() == "SEK" || this.tempSupplierInvoice.currency.trim().toUpperCase() == "OTHER") {
                return true;
            } else {
                return false;
            }
        },

        debitSummary() {
            const summary = this.voucherRows.reduce((total, amount) => {
                const result = total + amount.debit;
                return result.roundToEven2();
            }, 0);
            return summary;
        },

        creditSummary() {
            const summary = this.voucherRows.reduce((total, amount) => {
                const result = total + amount.credit;
                return result.roundToEven2();
            }, 0);
            return summary;
        },

        cantCreateVoucher() {
            return this.debitSummary === 0 || this.creditSummary === 0 || this.debitSummary !== this.creditSummary;
        },
    },
};
</script>
<style scoped>
table tr td {
    padding: 4px;
    font-size: 14px;
}
table td {
    border: 0px !important;
}
tr td:first-child {
    width: 124px;
}
</style>
